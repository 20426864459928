<template>
  <span
    class="badge badge-pill w-75"
    :class="buttonClass"
    :disabled="statusButtonText !== 'Approved' ? false : true"
    @click="
      () => {
        $emit('reportHours', shiftSlot);
      }
    "
  >
    {{ statusButtonText }}
  </span>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { MyDashboardPastShiftStatuses } from "@/core/helpers/shift_status.js";

export default defineComponent({
  name: "past-shift-status-button",
  props: {
    shiftSlot: Object
  },
  computed: {
    statusButtonText() {
      if (
        !MyDashboardPastShiftStatuses.includes(
          this.shiftSlot.applications[0].status
        )
      )
        return "Report!";
      else return this.shiftSlot.applications[0].status;
    },
    buttonClass() {
      switch(this.statusButtonText) {
        case "Report!":
          return "badge-light-danger"
        case "Reported":
          return "badge-light-primary"
        case "Denied":
          return "badge-light-warning"
        }
        return "badge-light-success"
    }
  }
});
</script>
