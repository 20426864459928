import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NextShiftWidget = _resolveComponent("NextShiftWidget")!
  const _component_UnpaidEarningsWidget = _resolveComponent("UnpaidEarningsWidget")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`d-flex ${_ctx.widgetPosition == 1 ? 'flex-column w-50' : 'w-100 flex-row'}  ps-4`)
  }, [
    _createVNode(_component_NextShiftWidget, {
      "widget-classes": `card w-100 ${_ctx.widgetPosition == 1 ? 'mb-4' : 'me-4'}`,
      "svg-icon": "/media/icons/duotone/General/gen013.svg",
      color: "success",
      "icon-color": "white",
      title: "Next shift",
      onHasNoShift: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hasNoShift')))
    }, null, 8, ["widget-classes"]),
    _createVNode(_component_UnpaidEarningsWidget, {
      class: _normalizeClass(`${_ctx.widgetPosition == 1 ? '' : 'ms-4'}`)
    }, null, 8, ["class"])
  ], 2))
}