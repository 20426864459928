
import { WorkerUnpaidEarnings } from "../users/userProfile/UndpaidApplicationWidgetAdmin.vue";
import { defineComponent, onMounted, ref } from "vue";
import CountUp from "vue-countup-v3";
import { useStore } from "vuex";

export default defineComponent({
  name: "unpaid-earnings-widget",
  components: { CountUp },
  setup: function() {
    const store = useStore();
    const earnings = ref<WorkerUnpaidEarnings>({
      totals: [],
      count: 0
    });
    const loading = ref<boolean>(false);
    const error = ref<boolean>(false);

    function fetchEarnings(): void {
      loading.value = true;
      store
        .dispatch("UsersModule/fetchUnpaidEarnings", null)
        .then((data: WorkerUnpaidEarnings) => {
          if (data) {
            earnings.value = data;
          }
          loading.value = false;
        })
        .catch(() => {
          error.value = true;
          loading.value = false;
        });
    }

    onMounted(() => {
      fetchEarnings();
    });

    return {
      earnings,
      loading,
      error
    };
  }
});
