import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column w-100" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "w-100 d-flex" }
const _hoisted_4 = { class: "card pt-4 mb-6 mb-xl-9 w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkerEarningsHistory = _resolveComponent("WorkerEarningsHistory")!
  const _component_MyDashboardWidgetsContainer = _resolveComponent("MyDashboardWidgetsContainer")!
  const _component_MyDashboardShiftsCard = _resolveComponent("MyDashboardShiftsCard")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(
        `w-100 ${
          _ctx.widgetPosition > 0 ? 'd-flex' : 'd-none'
        } flex-column mb-7 flex-md-row`
      )
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createVNode(_component_WorkerEarningsHistory, {
          onHasStatistics: _ctx.handleWidgetsPosition,
          class: "w-100 pe-4"
        }, null, 8, ["onHasStatistics"])
      ], 512), [
        [_vShow, _ctx.widgetPosition == 1]
      ]),
      _createVNode(_component_MyDashboardWidgetsContainer, { widgetPosition: _ctx.widgetPosition }, null, 8, ["widgetPosition"])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_MyDashboardShiftsCard)
        ], 512), [
          [_vShow, _ctx.widgetPosition > 0]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_loading_spinner)
        ], 512), [
          [_vShow, _ctx.widgetPosition == 0]
        ])
      ])
    ])
  ]))
}