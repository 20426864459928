
import {computed, defineComponent, PropType} from "vue";
import {
  DateTimeFormat,
  DefaultTimeSelectorStartEnd,
  timeSelectorStartEnd
} from "@/components/jobs/ReportHours/helpers";
import moment from "moment";

export default defineComponent({
  name: "reports-time-selector",
  props: {
    dateTimeString: {
      type: String as PropType<string>,
      default: () => null
    },
    minimumStartTime: {
      type: String as PropType<string>,
      default: () => null
    }
  },
  setup(props) {
    function buildStartEndFromTimeString(): timeSelectorStartEnd {
      const momentString = moment(props.dateTimeString, DateTimeFormat)
      if(momentString.isValid()) {
        const minutes = momentString.minutes();
        return {
          start: "00:" + minutes,
          end: "23:" + (minutes > 30 ? minutes : minutes + 30)
        }
      }
      return DefaultTimeSelectorStartEnd;
    }
    const getStartAndEndString = computed((): timeSelectorStartEnd =>
      props.dateTimeString == null ? DefaultTimeSelectorStartEnd : buildStartEndFromTimeString()
    );
    return{
      getStartAndEndString
    }
  }
})
