<template>
  <div class="card-body pt-0 pb-5" v-if="shifts">
    <div class="table-responsive">
      <table
        class="table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4"
      >
        <thead class="border-bottom border-gray-200 fs-7 fw-bolder no-padding">
          <tr class="fw-bolder text-muted">
            <th class="min-w-100px text-start">
              When
            </th>
            <th class="min-w-100px max-w-100px">
              Status
            </th>
            <th class="min-w-100px ">
              Job name
            </th>
            <th class="min-w-100px ">
              Client
            </th>
            <th class="min-w-100px ">
              Where
            </th>
            <th class="text-end min-w-100px ">
              Info
            </th>
          </tr>
        </thead>
        <tbody class="fs-7 fw-bold text-gray-700">
          <NewJobsTableShiftSlot
            v-for="(entry, index) in shifts"
            :key="index"
            :index="index"
            :entry="entry"
          ></NewJobsTableShiftSlot>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import NewJobsTableShiftSlot from "@/components/my-dashboard/Misc/NewJobsTableShiftSlot.vue"

export default defineComponent({
  name: "new-jobs-data-table",
  props: {
    shifts: Object
  },
  components: {
    NewJobsTableShiftSlot
  },
});
</script>
