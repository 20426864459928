
import { defineComponent, reactive, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "worker-earnings-history",
  emits: ["has-statistics"],
  setup: function(props, { emit }) {
    const store = useStore();
    const key = ref<number>(0);
    const data = computed(
      () => store.getters["StatisticsModule/workerEarnings"]
    );
    const chart = reactive({
      data: [
        {
          name: "Earnings",
          data: [] as string | unknown
        }
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        noData: {
          text: "Loading..."
        },
        xaxis: {
          type: "category",
          categories: [] as string[]
        },
        tooltip: {
          x: {
            format: "MMM yyyy"
          }
        }
      }
    });
    const setData = () => {
      if (data.value.length !== 0) {
        const datasets = {};
        for (const month in data.value) {
          const entry = data.value[month];
          Object.keys(entry).forEach(currency => {
            if (!datasets[currency]) {
              datasets[currency] = {
                name: currency,
                data: []
              };
            }
            const totalAmount = entry[currency].total_amount;
            datasets[currency].data.push(totalAmount);
          });
        }
        chart.options.xaxis.categories = Object.keys(data.value);
        chart.data = Object.values(datasets);
        emit('has-statistics', true);
        key.value++;
      } else {
        emit('has-statistics', false);
        chart.options.noData.text = "No statistics found";
        key.value++;
      }
    };

    function fetchStatistics() {
      store.dispatch("StatisticsModule/fetchWorkerEarnings").then(() => {
        setData();
      });
    }

    onMounted(() => {
      fetchStatistics();
    });
    return {
      chart,
      data,
      key
    };
  }
});
