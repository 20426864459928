<template>
  <span
    v-if="isShiftAlreadyCheckedIn"
    class="badge badge-pill w-100 mw-100px bg-light-success text-success"
    >Checked In</span
  >
  <div
    class="d-flex align-items-middle flex-column"
    :class="
      isAwaitingResponse.isLoading && isAwaitingResponse.indexes.includes(index)
        ? 'cursor-not-allowed'
        : ''
    "
    v-else
  >
    <a
      class="badge badge-pill cursor-pointer w-75"
      :class="[
        isCheckInOverdue
          ? 'badge-light-danger text-danger'
          : 'badge-light-success text-success',
        isAwaitingResponse.isLoading &&
        isAwaitingResponse.indexes.includes(index)
          ? 'pe-none'
          : ''
      ]"
      v-if="handleButtonRendering"
      @click="$emit('openModal', { ...entry, index: index })"
      data-bs-toggle="modal"
      data-bs-target="#singleActionModal"
    >
      <span
        class="spinner-border spinner-border-sm me-1"
        role="status"
        v-if="
          isAwaitingResponse.isLoading &&
            isAwaitingResponse.indexes.includes(index)
        "
      ></span>
      <span class="mb-1">{{ isCheckInOverdue ? "Overdue" : "Check In" }}</span>
    </a>
    <Countdown
      :class="
        handleButtonRendering
          ? 'badge badge-primary'
          : 'badge badge-light-primary text-primary'
      "
      class="w-75 mt-1"
      :eventTime="entry.start"
      :emptyLabel="entry.my_shift_slot.applications[0].status.name"
      :startCountingAtMinutes="2880"
      @emitedData="
        data => {
          renderedData = data;
        }
      "
    ></Countdown>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Countdown from "@/components/general/Countdown.vue";

export default defineComponent({
  name: "shift-check-in-button",
  components: {
    Countdown
  },
  props: {
    entry: Object,
    index: Number
  },
  data() {
    return {
      renderedData: null
    };
  },
  computed: {
    ...mapGetters("MyDashboardModule", ["isAwaitingResponse"]),
    handleButtonRendering() {
      if (this.renderedData == "Booked") return false;
      else return true;
    },
    isShiftAlreadyCheckedIn() {
      if (this.entry.my_shift_slot.applications[0].check_in_at !== null) return true;
      else return false;
    },
    isCheckInOverdue() {
      if(this.renderedData && this.renderedData.days == 0) return true;
      else return false;
    }
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
