
import { defineComponent, nextTick } from "vue";
import SearchField from "@/components/general/SearchField.vue";
import MyShiftsDataTable from "@/components/my-dashboard/DataTables/MyShiftsDataTable.vue";
import PendingShiftsDataTable from "@/components/my-dashboard/DataTables/PendingShiftsDataTable.vue";
import PastShiftsDataTable from "@/components/my-dashboard/DataTables/PastShiftsDataTable.vue";
import NewJobsDataTable from "@/components/my-dashboard/DataTables/NewJobsDataTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import ViewJobAddressModal from "@/components/my-dashboard/Misc/ViewJobAddressModal.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "my-dashboard-shifts-card",
  components: {
    SearchField,
    ViewJobAddressModal,
    MyShiftsDataTable,
    PendingShiftsDataTable,
    PastShiftsDataTable,
    NewJobsDataTable,
    LoadingSpinner,
    Pagination
  },
  data() {
    return {
      activeTab: "my",
      search: "",
      mapModalKey: 0,
      modal: null as Modal,
      mapModalPayload: null
    };
  },
  mounted() {
    this.setLoading(true);
    this.fetchShifts({
      id: this.currentUser.id,
      tab: this.activeTab,
      branches: this.currentUser.branches.map(a => a.id)
    }).then(() => {
      this.setLoading(false);
    });
    this.fetchForNewJobsCounter(this.currentUser.id);
  },
  methods: {
    ...mapActions("MyDashboardModule", [
      "fetchShifts",
      "setCurrentPage",
      "setLoading",
      "resetPagination",
      "setSearchString",
      "fetchForNewJobsCounter",
      "resetState"
    ]),
    async handleViewAddressModal(payload) {
      this.mapModalPayload = payload;
      this.mapModalKey++;
      await nextTick().then(() => {
        this.modal = new Modal(
          document.getElementById("view-job-address-modal")
        );
        this.modal.show();
      });
    },
    changeActiveTab(selectedTab) {
      this.activeTab = selectedTab;
      this.resetPagination();
      this.setLoading(true);
      this.fetchShifts({
        id: this.currentUser.id,
        tab: this.activeTab,
        branches: this.currentUser.branches.map(a => a.id)
      }).then(() => {
        this.setLoading(false);
      });
    },
    changeActivePage(pageNumber) {
      this.setCurrentPage(pageNumber);
      this.setLoading(true);
      this.fetchShifts({
        id: this.currentUser.id,
        tab: this.activeTab,
        branches: this.currentUser.branches.map(a => a.id)
      }).then(() => {
        this.setLoading(false);
      });
    },
    handleSearch(searchString) {
      this.resetPagination();
      this.setSearchString(searchString);
    }
  },
  computed: {
    ...mapGetters("MyDashboardModule", [
      "shifts",
      "loading",
      "getPagination",
      "newJobs"
    ]),
    ...mapGetters({ currentUser: "currentUser" })
  },
  beforeUnmount() {
    this.resetState();
  }
});
