
import UnpaidEarningsWidget from "@/components/widgets/UnpaidEarningsWidget.vue";
import NextShiftWidget from "@/components/widgets/NextShiftWidget.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "my-dashboard-widgets-container",
  components: { UnpaidEarningsWidget, NextShiftWidget },
  props: {
    widgetPosition: {
      type: Number as PropType<number>,
      required: true
    }
  },
  emits: ["hasNoShift"]
});
