
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  computed,
  PropType
} from "vue";
import {
  buildDateTimeString,
  getHoursFromString,
  DateTimeFormat
} from "@/components/jobs/ReportHours/helpers";
import ReportsTimeSelector from "@/components/help/misc/ReportsTimeSelector.vue";
import { useStore } from "vuex";
import moment from "moment";

type shiftSlot = {
  start: string;
  end: string;
};

export default defineComponent({
  name: "report-hours-card",
  components: {
    ReportsTimeSelector
  },
  props: {
    index: Number,
    shiftSlot: {
      type: Object as PropType<shiftSlot>,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const minimumStartTime = ref();
    const reportedHours = reactive({
      startTime: null as string | null,
      endTime: null as string | null,
      iwasLate: false,
      comment: null as string | null
    });
    const isAwaitingResponse = computed(
      () => store.getters["MyDashboardModule/isAwaitingResponse"]
    );
    onMounted(() => {
      reportedHours.startTime = minimumStartTime.value = getHoursFromString(
        props.shiftSlot.start,
        DateTimeFormat
      );
      reportedHours.endTime = getHoursFromString(
        props.shiftSlot.end,
        DateTimeFormat
      );
    });

    const saveData = () => {
      const startTime = moment(
        buildDateTimeString(props.shiftSlot.start, reportedHours.startTime),
        DateTimeFormat
      );
      const endTime = moment(
        buildDateTimeString(props.shiftSlot.end, reportedHours.endTime),
        DateTimeFormat
      );
      if (startTime.valueOf() > endTime.valueOf()) {
        endTime.add(1, "day");
      }
      emit("reportHours", {
        index: props.index,
        report: {
          start: startTime.format(DateTimeFormat),
          end: endTime.format(DateTimeFormat),
          iwasLate: reportedHours.iwasLate,
          comment: reportedHours.comment
        },
        shiftSlot: props.shiftSlot
      });
    };
    const endLabel = computed(() => {
      const startTime = moment(
        buildDateTimeString(props.shiftSlot.start, reportedHours.startTime),
        DateTimeFormat
      );
      const endTime = moment(
        buildDateTimeString(props.shiftSlot.end, reportedHours.endTime),
        DateTimeFormat
      );
      return startTime > endTime
        ? endTime.add(1, "day").format(DateTimeFormat)
        : endTime.format(DateTimeFormat);
    });
    return {
      minimumStartTime,
      isAwaitingResponse,
      reportedHours,
      saveData,
      endLabel
    };
  }
});
