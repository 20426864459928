<template>
  <div>
    <div class="row mb-5 pt-3">
      <div class="col-2">
        <label class="required fs-6 fw-bold mb-2">Start time</label>
        <div class="start">
          <ReportsTimeSelector
              v-model="reportedHour.startTime"
              :date-time-string="reportedHours[0].start"
              :minimum-start-time="minimumStartTime"
          />
          <span class="text-muted">{{ reportedHours[0].start }}</span>
        </div>
      </div>
      <div class="col-2">
        <label class="required fs-6 fw-bold mb-2">End time</label>
        <div class="start">
          <ReportsTimeSelector
            v-model="reportedHour.endTime"
            :date-time-string="reportedHours[0].end"
          />
          <span class="text-muted">{{ endLabel }}</span>
        </div>
      </div>
      <div class="col-2 d-flex align-items-center">
        <el-checkbox v-model="reportedHour.iwasLate">I was late</el-checkbox>
      </div>
      <div class="col-3">
        <label class="fs-6 fw-bold mb-2">Comment:</label>
        <div
          class="col-12
        "
        >
          <el-input
            class="w-100"
            placeholder="Enter comment..."
            v-model="reportedHour.comment"
          >
          </el-input>
        </div>
      </div>
      <div class="col-3">
        <div
          class="d-flex justify-content-end flex-row h-100 align-items-center"
        >
          <button
            @click="updateReportedHours"
            class="btn btn-sm btn-primary cursor-pointer me-3"
            :disabled="
              isAwaitingResponse.isLoading &&
                isAwaitingResponse.indexes.includes(index)
            "
          >
            <div
              v-show="
                isAwaitingResponse.isLoading &&
                  isAwaitingResponse.indexes.includes(index)
              "
              class="spinner-border spinner-border-sm me-1"
              role="status"
            ></div>
            Update report
          </button>
          <button
            class="btn btn-sm btn-danger cursor-pointer"
            @click="openDeleteModal"
            :disabled="
              isAwaitingResponse.isLoading &&
                isAwaitingResponse.indexes.includes(index)
            "
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
          >
            <div
              v-show="
                isAwaitingResponse.isLoading &&
                  isAwaitingResponse.indexes.includes(index)
              "
              class="spinner-border spinner-border-sm me-1"
              role="status"
            ></div>
            Delete report
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { getHoursFromString, buildDateTimeString, DateTimeFormat} from "@/components/jobs/ReportHours/helpers";
import ReportsTimeSelector from "@/components/help/misc/ReportsTimeSelector";
import moment from "moment";


export default defineComponent({
  name: "reported-hours-card",
  components:{
    ReportsTimeSelector
  },
  props: {
    index: Number,
    reportedHours: Object,
    shiftId: Object
  },
  setup(props, {emit}) {
    const store = useStore();
    const minimumStartTime = ref();
    const reportedHour = reactive({
      startTime: null,
      endTime: null,
      iwasLate: false,
      comment: null
    });
    const isAwaitingResponse = computed(() =>
      store.getters["MyDashboardModule/isAwaitingResponse"]
    );
    onMounted(() => {
      if(props.reportedHours) {
        reportedHour.comment = props.reportedHours[0].comment;
        reportedHour.startTime = minimumStartTime.value = getHoursFromString(props.reportedHours[0].start, DateTimeFormat);
        reportedHour.endTime = getHoursFromString(props.reportedHours[0].end, DateTimeFormat);
      }
    });

    const openDeleteModal = () => {
      emit("DeleteModalOpen",
          {index: props.index,
            reportId: props.reportedHours[0].id,
            shiftId: props.shiftId}
        );
    }

    const updateReportedHours = () => {
      const startTime = moment(
          buildDateTimeString(props.reportedHours[0].start, reportedHour.startTime),
          DateTimeFormat
      )
      const endTime = moment(
          buildDateTimeString(props.reportedHours[0].start, reportedHour.endTime),
          DateTimeFormat
      )
      if (startTime.valueOf() > endTime.valueOf()) {
        endTime.add(1, "day");
      }
      emit("updateReport",
          {index: props.index,
            shiftId: props.shiftId,
            report: {
              start: startTime.format(DateTimeFormat),
              end : endTime.format(DateTimeFormat),
              iwasLate: reportedHour.iwasLate,
              comment: reportedHour.comment},
          reportedHours: props.reportedHours})
    };

    const endLabel = computed(() => {
      const startTime = moment(
          buildDateTimeString(props.reportedHours[0].start, reportedHour.startTime),
          DateTimeFormat
      );
      const endTime = moment(
          buildDateTimeString(props.reportedHours[0].start, reportedHour.endTime),
          DateTimeFormat
      );
      return startTime > endTime ?
          endTime.add(1, 'day').format(DateTimeFormat) :
          endTime.format(DateTimeFormat)
    });

    return {
      reportedHour,
      updateReportedHours,
      endLabel,
      openDeleteModal,
      minimumStartTime,
      isAwaitingResponse
    }
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
