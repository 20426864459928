<template>
  <div class="py-5">
    <span class="fs-6"
    >Approved:
          <span class="text-success"
          >{{ reportedHours[0].duration }} hours -
            {{ reportedHours[0].report_time }}</span
          ></span
    >
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "aprroved-hours-card",
  props: {
    start: String,
    end: String,
    index: Number,
    reportedHours: Object
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
