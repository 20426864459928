<template>
  <DeleteModal
    :currentlySelectedEntry="'report'"
    @delete="handleDeleteReport"
  ></DeleteModal>
  <div class="card-body pt-0 pb-5" v-if="shifts">
    <div class="table-responsive">
      <table
        class="table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4"
      >
        <thead class="border-bottom border-gray-200 fs-7 fw-bolder no-padding">
          <tr class="fw-bolder text-muted">
            <th class="min-w-100px text-start">
              When
            </th>
            <th class="min-w-100px ">
              Status
            </th>
            <th class="min-w-100px ">
              Job name
            </th>
            <th class="min-w-100px ">
              Client
            </th>
            <th class="min-w-100px ">
              Where
            </th>
            <th class="min-w-100px text-end">
              Estimated earning
            </th>
            <th class="text-end min-w-100px ">
              Info
            </th>
          </tr>
        </thead>
        <tbody class="fs-7 fw-bold text-gray-700">
          <template v-for="(entry, index) in sortedShifts" :key="entry.id">
            <tr>
              <td>
                <router-link
                  class="fw-bold text-dark text-hover-primary"
                  :to="'/jobs/' + entry.job_id + '/shifts'"
                  >{{ entry.label.substring(0, 14) }} <br />
                  {{ entry.label.substring(14) }}</router-link
                >
              </td>
              <td>
                <PastShiftsStatusButton
                  :shiftSlot="entry.my_shift_slot"
                ></PastShiftsStatusButton>
              </td>
              <td>
                <router-link
                  class="fw-bold text-dark text-hover-primary"
                  :to="'/jobs/' + entry.job_id + '/shifts'"
                  >{{ entry.job.name
                  }}<span class="text-muted fw-bold d-block fs-7">{{
                    entry.my_shift_slot.skill.name
                  }}</span></router-link
                >
              </td>
              <td>
                <router-link :to="`/clients/${entry.job.company.id}`">
                  {{ entry.job.company.name }}
                </router-link>
              </td>
              <td>
                <a
                  class="text-dark fw-bolder d-block cursor-pointer"
                  @click="emitMapModalEvent(entry.job)"
                  ><span class="badge bg-primary fs-6 text-hover-info"
                    ><i class="fas fa-lg text-white fa-map-marked-alt mx-2"></i
                    >Map</span
                  >
                </a>
              </td>
              <td class="text-end">
                <div
                  v-if="loadingEstimates"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></div>
                <span v-else class="w-100">
                  {{
                    entry.my_shift_slot
                      ? `${
                          getEstimatedEarning(entry.my_shift_slot.id).estimate
                        } ${
                          getEstimatedEarning(entry.my_shift_slot.id).currency
                        }`
                      : "0.00"
                  }}
                </span>
              </td>
              <td class="text-end">
                <span
                  class="badge bg-primary"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse_${entry.id}`"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  See more
                </span>
              </td>
            </tr>

            <tr>
              <td colspan="7" class="p-0 m-0">
                <div
                  :id="`collapse_${entry.id}`"
                  class="collapse in bg-light px-3"
                  :class="{
                    show:
                      entry.my_shift_slot.applications[0].status === 'Booked'
                  }"
                >
                  <div class="row">
                    <div class="col-12">
                      <ApprovedHoursCard
                        v-if="
                          areThereReportedHours(
                            entry.my_shift_slot.reported_hours
                          ) &&
                            entry.my_shift_slot.applications[0].status ==
                              'Approved'
                        "
                        :index="index"
                        :reportedHours="
                          filterReportedHours(
                            entry.my_shift_slot.reported_hours
                          )
                        "
                      ></ApprovedHoursCard>
                      <ReportedHoursCard
                        v-else-if="
                          areThereReportedHours(
                            entry.my_shift_slot.reported_hours
                          )
                        "
                        @DeleteModalOpen="handleModalOpen"
                        @updateReport="handleUpdateReport"
                        :shiftId="entry.id"
                        :start="entry.start"
                        :end="entry.end"
                        :index="index"
                        :reportedHours="entry.my_shift_slot.reported_hours"
                      ></ReportedHoursCard>
                      <ReportHoursCard
                        v-else
                        @reportHours="handleReportHour"
                        :shiftSlot="entry"
                        :index="index"
                      ></ReportHoursCard>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import PastShiftsStatusButton from "@/components/my-dashboard/Misc/PastShiftsStatusButton.vue";
import UserBox from "@/components/widgets/user/UserBox.vue";
import ReportHoursCard from "@/components/my-dashboard/Misc/ReportHoursCard.vue";
import ReportedHoursCard from "@/components/my-dashboard/Misc/ReportedHoursCard.vue";
import ApprovedHoursCard from "@/components/my-dashboard/Misc/ApprovedHoursCard.vue";
import PhoneLink from "@/components/general/PhoneLink.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";
import { mapActions, mapGetters } from "vuex";
import {Collapse} from "bootstrap";

export default defineComponent({
  name: "past-shifts-data-table",
  props: {
    shifts: Object
  },
  data() {
    return {
      index: null,
      selectedReportID: null,
      shiftId: null
    };
  },
  components: {
    PastShiftsStatusButton,
    ReportedHoursCard,
    ApprovedHoursCard,
    ReportHoursCard,
    DeleteModal
  },
  methods: {
    ...mapActions("MyDashboardModule", [
      "reportHours",
      "updateReport",
      "deleteReport"
    ]),
    emitMapModalEvent(payload) {
      this.$emit("openMapModal", payload);
    },
    updateShiftSlot(oldSlot, newSlot, status) {
      const application = newSlot.applications.find(application => application.id == oldSlot.my_shift_slot.applications[0].id);
      if(application) {
        oldSlot.my_shift_slot = newSlot;
        oldSlot.my_shift_slot.applications[0] = application;
        oldSlot.my_shift_slot.applications[0].status = status;      }
    },
    handleReportHour(data) {
      if (data.shiftSlot.my_shift_slot.applications[0].status === 'Booked') {
        this.reportHours(data).then((res) => {
          this.updateShiftSlot(data.shiftSlot, res.shift_slot, "Reported")
        });
      } else {
        this.reportHours(data)
            .then((res) => {
              this.updateShiftSlot(data.shiftSlot, res.shift_slot, "Reported")
              const cardToCollapse = document.getElementById(`collapse_${data.shiftSlot.id}`);
              const collapse = new Collapse(cardToCollapse, {
                toggle: true
              })
            });
      }
    },
    handleUpdateReport(data) {
      this.updateReport(data)
          .then(() => {
            const cardToCollapse = document.getElementById(`collapse_${data.shiftId}`);
            const collapse = new Collapse(cardToCollapse, {
              toggle: true
            })
          });
    },
    getEstimatedEarning(shiftSlotId) {
     return shiftSlotId in this.estimatedEarning ? this.estimatedEarning[shiftSlotId] : 0.00;
    },
    handleDeleteReport() {
      this.deleteReport({index: this.index, reportId: this.selectedReportID, shiftId: this.shiftId});
    },
    handleModalOpen(payload) {
      this.index = payload.index;
      this.selectedReportID = payload.reportId;
      this.shiftId = payload.shiftId;
    },
    areThereReportedHours(data) {
      const reportedHours = data.filter(hour => hour.created_by_id == this.currentUser.id);
      if(reportedHours.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    filterReportedHours(data) {
      const hoursReportedByUser = data.filter(hour => hour.created_by_id == this.currentUser.id);
      return hoursReportedByUser
    }
  },
  computed: {
      ...mapGetters({
        currentUser : "currentUser"
      }),
      ...mapGetters("MyDashboardModule", ["estimatedEarning", "isLoadingEstimates"]),
      loadingEstimates() {
        return this.isLoadingEstimates;
      },
      sortedShifts() {
        const itemOrder = ["Booked","Report!","Reported","Approved", "Denied"];
        const shifts = [...this.shifts]
        shifts.sort((a, b) => itemOrder.indexOf(a.my_shift_slot.applications[0].status) - itemOrder.indexOf(b.my_shift_slot.applications[0].status));
        return shifts;
      }
    }
});
</script>
