<template>
  <span
    class="badge badge-rounded-pill bg-success width-fit-content align-items-center d-flex"
    :class="loading ? 'cursor-disabled pe-none' : 'cursor-pointer '"
    @click="applyForJob"
    ><span class="svg-icon svg-icon-white" v-if="!loading"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
            fill="#000000"
            fill-rule="nonzero"
            transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
          />
        </g></svg
    ></span>
    <div class="spinner-border spinner-border-sm me-1" v-if="loading"></div>
    Apply for {{ shiftSlot.skill.name }}</span
  ><span class="fs-7 text-muted">Estimated pay - {{ earnings }}</span>

</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "apply-for-job-button",
  props: {
    shiftSlot: Object,
    loading: Boolean
  },
  methods: {
    applyForJob() {
      this.$emit("apply", this.shiftSlot.id);
    }
  },
  computed: {
    ...mapGetters("MyDashboardModule", ["estimatedEarning"]),
    earnings() {
      if (this.shiftSlot.id in this.estimatedEarning) {
        const item = this.estimatedEarning[this.shiftSlot.id];
        return `${item.estimate} ${item.currency}`;
      }
      return "";
    }
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.width-fit-content {
  width: fit-content;
}
</style>
