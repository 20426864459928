<template>
  <SingleActionModal
    :setup="modalSetup"
    @actionButtonClicked="checkIn"
  ></SingleActionModal>
  <div class="card-body pt-0 pb-5" v-if="shifts">
    <div class="table-responsive">
      <table
        class="table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4"
      >
        <thead class="border-bottom border-gray-200 fs-7 fw-bolder">
          <tr class="fw-bolder text-muted">
            <th class="min-w-100px text-start">
              When
            </th>
            <th class="min-w-100px ">
              Status
            </th>
            <th class="min-w-100px ">
              Job name
            </th>
            <th class="min-w-100px ">
              Client
            </th>
            <th class="min-w-100px ">
              Where
            </th>
            <th class="min-w-100px text-end">
              Estimated earning
            </th>
            <th class="text-end min-w-100px ">
              Info
            </th>
          </tr>
        </thead>
        <tbody class="fs-7 fw-bold text-gray-700">
          <template v-for="(entry, index) in shifts" :key="entry.id">
            <tr>
              <td>
                <router-link
                  v-if="entry.job.status !==  statusCancelled"
                  class="fw-bold text-dark text-hover-primary"
                  :to="'/jobs/' + entry.job_id + '/shifts'"
                  >{{ entry.label.substring(0, 14) }} <br />
                  {{ entry.label.substring(14) }}</router-link
                >
                <span class="fw-bold text-dark text-decoration-line-through" v-if="entry.job.status == statusCancelled">
                  {{ entry.label.substring(0, 14) }} <br />
                  {{ entry.label.substring(14) }}
                </span>
              </td>
              <td>
                <ShiftCheckIn
                  v-if="shifts && entry.job.status !== 'Cancelled'"
                  @openModal="setSelectedShift"
                  :entry="entry"
                  :index="index"
                ></ShiftCheckIn>
                <div v-else>
                  <span class="badge badge-pill bg-warning">
                    Cancelled
                  </span>
                </div>
              </td>
              <td>
                <router-link
                  v-if="entry.job.status !== statusCancelled"
                  class="fw-bold text-dark text-hover-primary"
                  :to="'/jobs/' + entry.job_id + '/shifts'"
                  >{{ entry.job.name
                  }}<span
                    class="text-muted fw-bold text-muted d-block fs-7"
                    v-if="entry.my_shift_slot"
                    >{{ entry.my_shift_slot.skill.name }}</span
                  ></router-link
                >
                <div class="d-flex flex-column" v-if="entry.job.status == statusCancelled">
                  <span class="fw-bold text-dark text-decoration-line-through">{{ entry.job.name}}</span>
                  <span
                      class="text-muted fw-bold text-muted d-block fs-7"
                      v-if="entry.my_shift_slot"
                  >{{ entry.my_shift_slot.skill.name }}</span
                  >
                </div>
              </td>
              <td>
                <router-link
                  class="fw-bold text-dark text-hover-primary"
                  :to="`/clients/${entry.job.company.id}`"
                >
                  {{ entry.job.company.name }}
                </router-link>
              </td>
              <td>
                <a
                  class="text-dark fw-bolder d-block"
                  style="cursor:pointer"
                  @click="emitMapModalEvent(entry.job)"
                  ><span class="badge bg-primary fs-6 text-hover-info"
                    ><i class="fas fa-lg text-white fa-map-marked-alt mx-2"></i
                    >Map</span
                  >
                </a>
              </td>
              <td class="text-end">
                <div
                  v-if="loadingEstimates"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></div>
                <span v-else class="w-100">
                  {{
                    entry.my_shift_slot
                      ? getEstimatedEarning(entry.my_shift_slot.id)
                      : ""
                  }}
                </span>
              </td>
              <td class="text-end">
                <span
                  class="badge bg-primary"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse_${entry.id}`"
                  aria-expanded="false"
                >
                  See more
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="7" class="p-0 m-0">
                <div :id="`collapse_${entry.id}`" class="collapse in">
                  <div class="row">
                    <div class="col-6 p-4">
                      <span class="fs-6 fw-bold ">Your wanted contact:</span>
                      <div v-if="entry.job.project_manager">
                        <UserBox
                          class="pt-5 pb-5"
                          :user="entry.job.project_manager"
                        ></UserBox>
                        <PhoneLink
                          v-if="entry.job.project_manager"
                          :phone="entry.job.project_manager.phone"
                          css-classes="fs-6 fw-bold text-dark text-hover-primary"
                        ></PhoneLink>
                      </div>
                    </div>
                    <div class="col-6 mt-5 mb-5" v-if="entry.dresscode">
                      <span class="fs-6 text-danger">Dresscode: </span
                      ><span class="fs-6 fw-bold">{{
                        entry.dresscode.name
                      }}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="js">
import {defineComponent, nextTick} from "vue";
import ShiftCheckIn from "@/components/my-dashboard/Misc/ShiftCheckIn.vue"
import SingleActionModal from "@/components/general/SingleActionModal.vue";
import UserBox from "@/components/widgets/user/UserBox.vue";
import PhoneLink from "@/components/general/PhoneLink";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "my-shifts-data-table",
  data() {
    return {
      statusCancelled: "Cancelled",
      modalSetup: {
        modalText: "Are you sure you want to check in",
        buttonText: "Check in",
        buttonClass: "btn btn-success"
      },
      selectedShift: {}
    };
  },
  props: {
    shifts: Object
  },
  components: {
    SingleActionModal,
    ShiftCheckIn,
    UserBox,
    PhoneLink
  },
  computed: {
    ...mapGetters("MyDashboardModule", ["estimatedEarning", "isLoadingEstimates"]),
    loadingEstimates() {
      return this.isLoadingEstimates;
    }
  },
  methods: {
    ...mapActions("MyDashboardModule", ["CheckInWorker"]),
    getEstimatedEarning(shiftSlotId) {
      if (shiftSlotId in this.estimatedEarning) {
        const item = this.estimatedEarning[shiftSlotId];
        return `${item.estimate} ${item.currency}`;
      }
      return "";
    },
    checkIn() {
      this.CheckInWorker(this.selectedShift);
    },
    emitMapModalEvent(payload) {
      this.$emit("openMapModal", payload);
    },
    setSelectedShift(data) {
      this.selectedShift = data;
    }
  }
});
</script>
