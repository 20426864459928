<template>
  <div class="modal fade" id="singleActionModal" data-toggle="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="singleActionModalLabel">Are you sure?</h5>
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>
        <div class="modal-body">
          <p>{{ setup.modalText }} ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Close
          </button>
          <button
            @click="buttonClicked"
            type="button"
            :class="setup.buttonClass"
            data-bs-dismiss="modal"
          >
            {{ setup.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name:"SingleActionModal",
  props: {
    setup: Object
  },
  methods: {
    buttonClicked() {
      this.$emit("actionButtonClicked", this.setup.action);
    }
  }
});
</script>
