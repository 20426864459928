
import {defineComponent, PropType} from "vue";
type modalPayload = {
  address: string;
  venue: {
    address: string;
    city: string;
    entrance: string;
    name: string;
  }
}
export default defineComponent({
    name: "view-job-address-modal",
    props: {
      payload: {
        type: Object as PropType<modalPayload|null>,
        default: () => null
      }
    }
})
