<template>
  <MyDashboardIndex></MyDashboardIndex>
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import MyDashboardIndex from "@/components/my-dashboard/Index.vue";

export default defineComponent({
  name: "my-dashboard",
  components: {
    MyDashboardIndex
  },
  mounted() {
    setCurrentPageTitle("My Dashboard");
  }
});
</script>
