
import { defineComponent } from "vue";
import PendingShiftsButton from "@/components/my-dashboard/Misc/PendingShiftsButton.vue";
import PhoneLink from "@/components/general/PhoneLink.vue";
import SingleActionModal from "@/components/general/SingleActionModal.vue";
import UserBox from "@/components/widgets/user/UserBox.vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "pending-shifts-data-table",
  props: {
    shifts: Object
  },
  components: {
    SingleActionModal,
    PendingShiftsButton,
    UserBox,
    PhoneLink
  },
  data() {
    return {
      modalSetup: {
        modalText: "Are you sure you want to un-apply",
        buttonText: "Un-Apply",
        buttonClass: "btn btn-secondary fs-6"
      },
      selectedShiftSlot: {}
    };
  },
  computed: {
    ...mapGetters("MyDashboardModule", [
      "estimatedEarning",
      "isLoadingEstimates"
    ]),
    loadingEstimates() {
      return this.isLoadingEstimates;
    }
  },
  methods: {
    ...mapActions("MyDashboardModule", ["unApplyWorker"]),
    handleModalOpen(data) {
      this.selectedShiftSlot = data;
    },
    getEstimatedEarning(shiftSlotId) {
      if (shiftSlotId in this.estimatedEarning) {
        const item = this.estimatedEarning[shiftSlotId];
        return `${item.estimate} ${item.currency}`;
      }
      return "";
    },
    emitMapModalEvent(payload) {
      this.$emit("openMapModal", payload);
    },
    handleUnapplyWorker() {
      this.unApplyWorker(this.selectedShiftSlot);
    }
  }
});
