
import MyDashboardWidgetsContainer from "@/components/my-dashboard/Misc/WidgetsContainer.vue";
import MyDashboardShiftsCard from "@/components/my-dashboard/Tabs/ShiftsCard.vue";
import WorkerEarningsHistory from "@/components/charts/WorkerEarningsHistory.vue";
import { defineComponent, ref } from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";

export default defineComponent({
  name: "my-dashboard-index",
  components: {
    LoadingSpinner,
    MyDashboardWidgetsContainer,
    WorkerEarningsHistory,
    MyDashboardShiftsCard
  },
  setup() {
    const widgetPosition = ref<number>(0);

    function handleWidgetsPosition(hasStatistics: boolean): void {
      widgetPosition.value = hasStatistics ? 1 : 2;
    }

    return {
      handleWidgetsPosition,
      widgetPosition
    };
  }
});
