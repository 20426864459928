import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_time_select = _resolveComponent("el-time-select")!

  return (_openBlock(), _createBlock(_component_el_time_select, {
    start: _ctx.minimumStartTime ? _ctx.minimumStartTime : _ctx.getStartAndEndString.start,
    end: _ctx.getStartAndEndString.end,
    class: "w-100 mb-2",
    step: "00:30",
    placeholder: "Select time"
  }, null, 8, ["start", "end"]))
}