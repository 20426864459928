<template>
  <div>
    <a
      v-if="myShiftSlot.status !== 'Denied'"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      class="badge badge-pill cursor-pointer w-75"
      :style="`pointer-events: ${isAwaitingResponse.isLoading && isAwaitingResponse.indexes.includes(index) ? 'none' : 'auto'}`"
      :class="
        hover ? 'cursor-pointer badge-dark text-white' : ' badge-secondary'
      "
      @click="openUnapplyModal
      "
      data-bs-toggle="modal"
      data-bs-target="#singleActionModal"
    >
      <span
        v-show="isAwaitingResponse.isLoading && isAwaitingResponse.indexes.includes(index)"
        class="spinner-border spinner-border-sm me-1"
        role="status"
      ></span>
      <span class="mb-1">{{ hover ? "Un-Apply" : myShiftSlot.status }}</span>
    </a>
    <span class="badge badge-danger w-75" v-else>
      {{ myShiftSlot.status }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "pending-shifts-button",
  props: {
    myShiftSlot: Object,
    index: Number,
    shiftId: Number
  },
  data() {
    return {
      hover: false
    };
  },
  computed: {
    ...mapGetters("MyDashboardModule", ["isAwaitingResponse"])
  },
  methods: {
    openUnapplyModal() {
      if (this.isAwaitingResponse.isLoading && this.isAwaitingResponse.indexes.includes(this.index)) {
        return
      } else {
        this.$emit('openUnapplyModal', {
          ...this.myShiftSlot,
          shiftId: this.shiftId,
          index: this.index
        })
      }
    }
  }
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
