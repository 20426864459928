
import { defineComponent, onMounted, computed, ref } from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useRouter } from "vue-router";
import moment from "moment";
import { useStore } from "vuex";

export default defineComponent({
  name: "next-shift-widget",
  props: {
    widgetClasses: String,
    color: String,
    iconColor: String,
    svgIcon: String,
    title: String
  },
  components: {
    LoadingSpinner
  },
  setup(_, { emit }) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const error = ref<boolean>(false);
    const router = useRouter();
    const shift = computed(() => store.getters["WidgetsModule/getNextShift"]);
    onMounted(() => {
      loading.value = true;
      store
        .dispatch("WidgetsModule/fetchNextShift")
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          error.value = true;
          loading.value = false;
          emit("hasNoShift");
        });
    });

    const remainingTime = computed(() => {
      return moment(shift.value.start, "DD/MM/YYYY kk:mm").fromNow();
    });
    const cardLabel = computed(() => {
      return moment(shift.value.start, "DD/MM/YYYY kk:mm").format("ddd kk:mm");
    });
    function handleRoute() {
      if (!error.value) {
        router.push(
          `/jobs/${shift.value.job_id}/shifts?shift=${shift.value.id}`
        );
      }
    }
    return {
      remainingTime,
      cardLabel,
      handleRoute,
      shift,
      error,
      loading
    };
  }
});
