import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card-body"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleRoute && _ctx.handleRoute(...args))),
    class: _normalizeClass([[_ctx.widgetClasses, `bg-${_ctx.color}`], "card hoverable"])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
          key: 0,
          spinnerColor: 'text-white'
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass([`svg-icon-${_ctx.iconColor}`, "svg-icon svg-icon-3x mx-n1"])
          }, [
            _createVNode(_component_inline_svg, { src: _ctx.svgIcon }, null, 8, ["src"])
          ], 2),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-2 mb-2 mt-5"])
              }, " No upcoming shifts ", 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-2 mb-2 mt-5"])
                }, _toDisplayString(_ctx.cardLabel), 3),
                _createElementVNode("div", {
                  class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bolder fs-2 mb-2"])
                }, _toDisplayString(_ctx.remainingTime), 3),
                _createElementVNode("div", {
                  class: _normalizeClass([`text-inverse-${_ctx.color}`, "fw-bold fs-7"])
                }, _toDisplayString(_ctx.title), 3)
              ]))
        ]))
  ], 2))
}