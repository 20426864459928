<template>
  <span>{{ renderHandler }}</span>
</template>

<script lang="js">
import { defineComponent } from "vue";
import moment from "moment";
import Countdown from "countdown-js";

export default defineComponent({
  name: "countdown",
  props: {
    eventTime: String,
    emptyLabel: String,
    startCountingAtMinutes: Number
  },
  data() {
    return {
      timer: "",
      timeOFEventinMS: "",
      formatedCountdown: ""
    };
  },
  mounted() {
    this.timeOFEventinMS = moment(this.eventTime, "DD-MM-YYYY HH:Ss").valueOf()
    const difference = moment(this.timeOFEventinMS).diff(Date.now());
    if (moment(this.timeOFEventinMS).diff(Date.now(), "minutes") < this.startCountingAtMinutes && difference > 0) {
        this.timer = Countdown.timer(this.timeOFEventinMS, (timeLeft) => {
          this.$emit("emitedData", timeLeft);
          if(timeLeft.days == 0) {
            this.formatedCountdown = `${timeLeft.hours}h:${timeLeft.minutes}m`;
          } else {
            this.formatedCountdown = `${timeLeft.days}d:${timeLeft.hours}h`;
          }
        }, () => {
          this.$emit("countdownFinished", true);
        })
      } else {
        this.$emit("emitedData", this.emptyLabel);
        return this.emptyLabel;
      }
  },
  computed: {
    renderHandler() {
      if(moment(this.timeOFEventinMS).diff(Date.now(), "minutes") > this.startCountingAtMinutes) {
        this.$emit("emitedData", this.emptyLabel);
        return this.emptyLabel;
      }
      else {
        return this.formatedCountdown;
      }
    }
  },
  beforeUnmount() {
    if(this.timer) this.timer.abort();
  }
});
</script>
