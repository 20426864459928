<template>
  <tr>
    <td>
      <router-link
        class="fw-bold text-dark text-hover-primary"
        :to="'/jobs/' + entry.job_id + '/shifts'"
        >{{ entry.label.substring(0, 14) }} <br />
        {{ entry.label.substring(14) }}</router-link
      >
    </td>
    <td>
      <div class="d-flex flex-column">
        <ApplyForJobButton
          v-for="(slot, index) in nonAppliedShiftSlots(entry.shift_slots)"
          :shiftSlot="slot"
          :class="index > 0 ? 'mt-2' : ''"
          class="align-items-center"
          :key="index"
          @apply="handleApply"
          :loading="loading"
        ></ApplyForJobButton>
      </div>
    </td>
    <td>
      <router-link
        class="fw-bold text-dark text-hover-primary"
        :to="'/jobs/' + entry.job_id + '/shifts'"
        >{{ entry.job.name }}</router-link
      >
    </td>
    <td>
      <router-link
        class="fw-bold text-dark text-hover-primary"
        :to="`/clients/${entry.job.company.id}`"
      >
        {{ entry.job.company.name }}
      </router-link>
    </td>
    <td>
      <a
        class="text-dark fw-bolder d-block"
        href=""
        data-bs-target="#mapModal"
        data-bs-toggle="modal"
        ><span class="badge bg-primary fs-6 text-hover-info"
          ><i class="fas fa-lg text-white fa-map-marked-alt mx-2"></i>Map</span
        >
      </a>
    </td>
    <td class="text-end">
      <span
        class="badge bg-primary"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="`#collapse_${entry.id}`"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        See more
      </span>
    </td>
  </tr>

  <tr>
    <td colspan="7" class="p-0 m-0">
      <div :id="`collapse_${entry.id}`" class="collapse in">
        <div class="row">
          <div class="col-6 p-4">
            <span class="fs-6 fw-bold ">Your wanted contact:</span>
            <div v-if="entry.job.project_manager">
              <UserBox
                class="pt-5 pb-5"
                :user="entry.job.project_manager"
              ></UserBox>
              <a
                class="badge bg-primary mb-1"
                :href="`tel:${entry.job.project_manager.phone}`"
                >Phone : {{ entry.job.project_manager.phone }}</a
              >
            </div>
          </div>
          <div class="col-6 mt-5 mb-5">
            <span class="fs-6 text-danger">Dresscode: </span
            ><span class="fs-6 fw-bold" v-if="entry.dresscode">{{
              entry.dresscode.name
            }}</span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import ApplyForJobButton from "@/components/my-dashboard/Misc/ApplyForJobButton.vue";
import UserBox from "@/components/widgets/user/UserBox.vue";

export default defineComponent({
  name: "new-jobs-table-shift-slot",
  props: {
    entry: Object,
    index: Number
  },
  data: () => ({
    loading: false
  }),
  components: {
    UserBox,
    ApplyForJobButton
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  },
  methods: {
    ...mapActions("MyDashboardModule", ["applyForShift"]),
    handleApply(shiftSlotID) {
      const payload = {
        shift_slots: [shiftSlotID],
        created_by_id: this.currentUser.id,
        assignee_id: this.currentUser.id,
        status: "Applied"
      };
      this.loading = true;
      this.applyForShift(payload).finally(() => {
        this.loading = false;
      });
    },
    nonAppliedShiftSlots(data) {
      const shallowCopy = data;
      if (shallowCopy.filter(a => a.applications.length > 0).length == 0) {
        //If the shift slots have no applicants we just return the original array
        return data;
      } else {
        // else if there are applicants
        const shiftSlotIds = shallowCopy
          .filter(a => a.applications.length > 0)
          .map(a => a.applications)
          .flat()
          .filter(a => a.assignee_id == this.currentUser.id)
          .map(a => a.pivot.shift_slot_id);
        return data.filter(a => !shiftSlotIds.includes(a.id)); //we filter the shift slots* with that id
      }
    }
  }
});
</script>
